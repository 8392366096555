.input_align_end {
    input { 
        text-align: end; 
    }
}

.padding_table {
    padding-top: 0.25rem !important; 
    padding-bottom: 0.25rem !important;
}

.padding_draggle {
    padding-top: 0.50rem !important; 
    padding-bottom: 0.50rem !important;
}

.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: hsla(0,0%,100%,.7);
    margin: 0;
    display: block;
}

.leaflet-tooltip.class-tooltip-dark {
    background: #0b1727;
    border: 1px solid #344050
  }
  
  .leaflet-tooltip-left.class-tooltip-dark::before {
    border-left-color: #344050;
  }
  
  .leaflet-tooltip-right.class-tooltip-dark::before {
    border-right-color: #344050;
  }

  .leaflet-tooltip.class-tooltip-sun {
    background: #f9fafd;
    border: 1px solid #d8e2ef
  }
  
  .leaflet-tooltip-left.class-tooltip-sun::before {
    border-left-color: #d8e2ef;
  }
  
  .leaflet-tooltip-right.class-tooltip-sun::before {
    border-right-color: #d8e2ef;
  }

  .overlay_card {
    /* overlay */
    box-shadow: 0 0 0 100px inset, 0 0 5px grey;
    /* hover out transition */
    transition: box-shadow 1s;
  }
  
  .overlay_card::after {
    width: 80%;
    height: 80%;
    display: block;
    white-space: pre;
    font: 15pt 'sigmar one';
    color: white;
    border: 2px solid;
    text-align: center;
    /* center content using flex box */
    display: flex;
    justify-content: center;
    align-items: center;
    /* hover out transition */
    transition: opacity 1s .5s;
  }

  .overlay_card:hover {
    box-shadow: 0 0 0 5px inset, 0 0 5px grey, 0 0 10px grey inset;
    transition: box-shadow 1s;
  }
  
  .overlay_card:hover::after {
    opacity: 0;
    transition: opacity .5s;
  }