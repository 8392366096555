@media (max-width: 576px)
{
    .mb-20-maxw-576{
        margin-bottom: 20px;
    }

    .mr-0-maxw-576{
        margin-right: 0 !important;
    }

    .ml-0-maxw-576{
        margin-left: 0 !important;
    }

    .pr-0-maxw-576{
        padding-right: 0 !important;
    }


    .txt-c-576{
        text-align: center !important;
    }





    /* -----------------    PERFROMANCE BY REGION PROVINCE    ----------------- */

    /* margin: t r b l */
}