.customize_is_dark {
    color: var(--falcon-input-color) !important;
    background-color: var(--falcon-input-bg) !important;
    background-clip: padding-box !important;
    border: 1px solid var(--falcon-input-border-color) !important;

    .ui.label {
        background-color: var(--falcon-gray-200)!important;
        color: var(--falcon-body-color)!important;
    }

    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
        cursor: default;
    }

    a.ui.label {
        cursor: default;
    }
}

.customize_measure_not_available {
    color: red !important;
    background-color: var(--falcon-input-bg) !important;
    background-clip: padding-box !important;
    border: 1px solid var(--falcon-input-border-color) !important;

    .ui.label {
        background-color: var(--falcon-gray-200)!important;
        color: var(--falcon-body-color)!important;
    }

    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
        cursor: default;
    }

    a.ui.label {
        cursor: default;
    }
}

.customize_measure_available {
    color: green !important;
    background-color: var(--falcon-input-bg) !important;
    background-clip: padding-box !important;
    border: 1px solid var(--falcon-input-border-color) !important;

    .ui.label {
        background-color: var(--falcon-gray-200)!important;
        color: var(--falcon-body-color)!important;
    }

    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
        cursor: default;
    }

    a.ui.label {
        cursor: default;
    }
}

.ui.multiple.dropdown>.label {
    font-size: 12px !important;
}


.ui.dropdown .menu >.item {
    color: var(--falcon-input-color) !important;
    background-color: var(--falcon-input-bg) !important;
    background-clip: padding-box !important;
    border: 1px solid var(--falcon-input-border-color) !important;
}

    
.ui.selection.dropdown .menu >.item {
    padding: 7px !important;
}

.ui.selection.active.dropdown .menu {
    border: 1px
}

.customize_error {
    background-clip: padding-box !important;
    border: 1px solid red !important;
}